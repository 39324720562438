<template>

  <div>

    <!-- MODAL : NEWSLETTER SUBSCRIPTION -->

    <Modal ref="nlSub">

      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-newspaper fas-left"></i> <translate>NEWS</translate> <small class="color01">_ <translate>FROM FARMGOD</translate></small></h5>
      </template>
      <template v-slot:body>
        <link href="//cdn-images.mailchimp.com/embedcode/slim-10_7.css" rel="stylesheet" type="text/css">
        <div id="mc_embed_signup" style="margin: auto;">
          <form action="https://farmgod.us1.list-manage.com/subscribe/post?u=8f3f1ed7be3a6fd5f14374053&amp;id=89f54f84e3" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
            <div id="mc_embed_signup_scroll">
              <label for="mce-EMAIL" style="display:none;"><translate>Subscribe</translate></label>
              <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div style="position: absolute; left: -5000px;" aria-hidden="true">
                <input type="text" name="b_8f3f1ed7be3a6fd5f14374053_89f54f84e3" tabindex="-1" value="">
              </div>
              <div class="clear" style="">
                <div style="width: 400px; padding: 0px; text-align: center; margin-top: -5px; margin-bottom:10px;">
                  <span class="color03"><b><translate>KEEP IN TOUCH WITH FARMGOD</translate></b></span><br /><br />
                   <translate>Please hit the button below to subscribe to the FarmGod newsletter and be the first to read news when they arrive!</translate><br /><br />
                  <input type="submit" @click="$refs.nlSub.closeModal();" style="width: 75%;" :value="$gettext('SUBSCRIBE TO OUR NEWSLETTER')" name="subscribe" id="mc-embedded-subscribe" class="next-btn btn btn-success trans">
                </div>
              </div>
            </div>
          </form>
        </div>
      </template>
    </Modal>

  </div>

</template>

<style type="text/css">
  #mc_embed_signup{background:none; clear:left; font:14px Helvetica,Arial,sans-serif; }
  #mc_embed_signup input.email {
      font-family: "Open Sans","Helvetica Neue",Arial,Helvetica,Verdana,sans-serif;
      font-size: 13px;
      width: 80%;
      margin: auto;
      margin-bottom: 10px;
      background: #ffffff12;
      padding: 9px;
      text-align: center;
      color: #fff!important;
  }
  #mc_embed_signup .button {
      clear: both;
      background-color: #353535;
      border: 0 none;
      border-radius: 4px;
      letter-spacing: .03em;
      color: #FFFFFF;
      cursor: pointer;
      display: inline-block;
      font-size: 15px;
      height: 32px;
      line-height: 32px;
      margin: 0 5px 10px 0;
      padding: 0;
      text-align: center;
      text-decoration: none;
      vertical-align: top;
      white-space: nowrap;
      width: auto;
      margin: auto!important;
      transition: all 0.23s ease-in-out 0s;
  }
  #mc_embed_signup input.button {
    display: block;
    width: 35%;
    margin: 0 0 10px 0;
    font-size: 10px;
    height: 30px;
    line-height: 30px;
    width: 200px;
    color: #adadad;
    border-radius: 0;
    background-color: #2b2b2bcc;
    letter-spacing: 0;
  }
  .copyright_alt .fg-modal__footer {
    display: none;
  }
  /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
     We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>

<script>
import Modal from '@/components/widgets/Modal.vue'

export default {
  name: 'NewsletterSub',
  components: { Modal },
  created () {
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
