<template>
  <footer class="row">
    <slot></slot>
  </footer>
</template>

<script>
export default {
  name: 'FarmgodFooter'
}
</script>
