<template>
  <div id='logo' class='logo'>
    <router-link v-if='link !== "none"' class="navbar-brand" :to="{ name: 'overview' }">
      <img src='@/assets/theme/logo.png' />
    </router-link>
    <img v-else src="@/assets/theme/logo.png" />
  </div>
</template>

<script>

export default {
  name: 'logo',
  props: ['title', 'link']
}
</script>
