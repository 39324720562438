<template>
  <transition name="fade">
    <div class="fg-modal" v-if="show">
      <div class="fg-modal__backdrop" @click="closeModal()" style="cursor:pointer;"/>

      <div class="fg-modal__dialog">
        <div class="fg-modal__header">
          <slot name="header"/>
          <div @click="closeModal()" style="cursor:pointer;" :title="$gettext('Close this window')">
            <b><i class="fas fas-xsBtn fa-times-circle modalClose trans"></i></b>
          </div>
        </div>

        <div class="fg-modal__body">
          <slot name="body"/>
        </div>

        <div class="fg-modal__footer">
          <slot name="footer"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  data () {
    return {
      show: false
    }
  },
  created () {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.show = false
      }
    })
  },
  methods: {
    closeModal () {
      this.show = false
      document.querySelector('body').classList.remove('overflow-hidden')
    },
    openModal () {
      this.show = true
      document.querySelector('body').classList.add('overflow-hidden')
    }
  }
}
</script>

<style lang="scss" scoped>
.fg-modal {
  overflow-x: hidden;
  overflow-y: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  &__backdrop {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  &__dialog {
    background-color: #252525;
    position: relative;
    width: 40%;
    margin: 17% auto;
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    overflow: hidden;
    z-index: 2;
    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }
  &__close {
    width: 30px;
    height: 30px;
  }
  &__header {
    padding: 20px 20px 15px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;
    border-bottom: 1px solid #d6d6d6;
    background-color: #353535;
  }
  &__body {
    padding: 10px 20px 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  &__footer {
    padding: 15px 20px 15px;
    border-top: 1px solid #d6d6d6;
    background-color: #353535;
    margin-top: 15px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
