<template>
  <div class="lang-switch">
    <!-- keep dropdown open for debugging:
    :dropdown-should-open="() => true"
     -->
    <!--
    <translate>Language</translate>
    -->
    <v-select v-model="$language.current" :options="langs" :reduce="lang => lang.code" @input="saveDefaultLanguage" :clearable="false" :searchable="false">
      <template #selected-option="option">
        <span class="flag-icon" :class="getLangCodeClass(option.code)"></span>
        {{ option.label }}
      </template>
      <template #option="option">
        <span class="flag-icon" :class="getLangCodeClass(option.code)"></span>
        {{ option.label }}
      </template>
    </v-select>
  </div>
</template>

<script>
import TranslationService from '../../common/translation.service'

export default {
  name: 'LanguageSwitcher',
  methods: {
    saveDefaultLanguage (e) {
      TranslationService.saveDefaultLanguage(e)
      // console.log('$formulate.setLocale: ' + e)
      this.$formulate.setLocale(e)
    },
    getLangCodeClass (code) {
      if (code === 'en') code = 'gb'
      if (code === 'de') code = 'at'
      return 'flag-icon-' + code
    }
  },
  computed: {
    langs () {
      const langs = []
      Object.entries(this.$language.available).forEach(([key, value]) => {
        langs.push({ code: key, label: value })
      })
      return langs
    }
  }
}
</script>

<style>
.lang-switch {
  margin-top: -5px;
  font-size: 0.8em;
  position: absolute;
  right: 25px;
}
.lang-switch .v-select {
  display: inline-block;
  width: auto;
  min-width: 9em;
}
/* .vs--single.vs--open .vs__selected { position: inherit; } */
.lang-switch .vs__selected {
  color: white;
}
.lang-switch .vs__selected .flag-icon {
  margin-right: 5px;
}
.lang-switch input.vs__search:focus {
  border: 1px solid transparent !important;
  border-left: none !important;
  outline: none !important;
}
.lang-switch .vs--single .vs__selected {
  position: absolute;
  opacity: .75;
}
.lang-switch .vs__search::placeholder,
.lang-switch .vs__dropdown-toggle,
.lang-switch .vs__dropdown-menu {
  background: #1b1b1b;
  color: white;
}
.lang-switch .vs__dropdown-option {
  color: white;
}
.lang-switch .vs__dropdown-option--highlight {
  background-color: gray;
  color: white;
}
.lang-switch .vs__clear,
.lang-switch .vs__open-indicator {
  fill: gray;
}
</style>
