<template>
  <header class="row">
    <div class="container-fluid">
      <LanguageSwitcher v-if="this.$route.name === 'login' && product_mode === 'bos' || this.$route.name !== 'login' && product_mode === 'pro' || product_mode === 'bos'" />
      <slot></slot>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

import LanguageSwitcher from '@/components/widgets/LanguageSwitcher.vue'
import PRODUCT_MODE from '@/common/config'

export default {
  name: 'FarmgodHeader',
  components: { LanguageSwitcher },
  data () {
    return {
      product_mode: PRODUCT_MODE
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    isLoginPage () {
      if (this.$route.name === 'login') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
